


/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        var body = $('body');
        var preloader = $('.preloader-wrapper');
        var vpWidth = $(window).width();
        var vpHeight = $(window).height();

        $.fn.checkActive = function() {
          if ($(this).hasClass('active')) {
            $(this).removeClass('active');
          } else {
            $(this).addClass('active');
          }
        };

        $.fn.animateScrollTo = function(offset, speed) {

          offset = offset || 0;
          speed = speed || 500;

          $('html, body').stop().animate({
            'scrollTop': $(this).offset().top + offset
          }, speed);

        };

        body.on('click', '.scroll', function() {
          var id = $(this).data('id');
          $('#'+id).animateScrollTo();
        });

        $.fn.stars = function () {
          return $(this).each(function () {
                // Get the value
                var val = parseFloat($(this).html());
                // Make sure that the value is in 0 - 5 range, multiply to get width
                val = Math.round(val * 4) / 4; /* To round to nearest quarter */
                var size = Math.max(0, (Math.min(5, val))) * 12;
                // Create stars holder
                var $span = $('<span />').width(size);
                // Replace the numerical value with stars
                $(this).html($span);
              });
        };

        $.fn.activateTabContent = function(id, tabClass) {
          $(tabClass).removeClass('active');
          $(id).addClass('active');
          $(this).addClass('active');
        };

        body.on('click','.tab-activator', function() {
          var id = $(this).data('id');
          var tabClass = $(this).data('tabclass');
          $(this).activateTabContent(id, tabClass);
        });

        $('span.stars').stars();
        //$('select').material_select();

        var clicked_button;
        body.on('click','button[type=submit],a.my-profile',function() {
          clicked_button = $(this);
        });

        body.on('click','.favorites, .modal-trigger',function(e) {
          e.stopPropagation();
        });

        // Checks if main navigation does not fit and creates alternate menu
        function feedAuxMenu() {
          if ($(window).width() > 400) {
            var drop_menu = $('#nav__dropdown-menu');
            var drop_button = $('.nav__dropdown');
            drop_menu.html('');
            $('nav > a').each(function() {
              if ($(this).offset().top > $('nav.header__nav-options').offset().top || $('.header__nav').css('width') === 0) {
                $('#nav__dropdown-menu').append($(this).clone().wrapAll('<p/>').parent().html());
              }
            });
            if (drop_menu.html() !== '') {
              drop_button.css('visibility','visible');
            } else {
              drop_button.css('visibility','hidden');
            }
          }
        }
        // Brings action buttons on Box to bottom
        function accommodateBoxActions() {
          $('.box-info').each(function() {
            title = $(this).find('.box-title').outerHeight();
            meta = $(this).find('.item-main-meta').outerHeight();
            action = $(this).find('.box-actions');
            offset = 165 - title - meta - 32;
            $(this).find('.box-actions').css('margin-top', offset );
          });
        }

        $(window).on('resize', function() {
          feedAuxMenu();
        });
        $(window).load(function(){
            feedAuxMenu();
            //accommodateBoxActions();
        });
    
        function afterUpdateFragments(data) {

          $('#sign-log').replaceWith(data.loggedMenu);

          if (data.logged) {

            $('.my-profile.modal-trigger').leanModal({
                dismissible: true, // Modal can be dismissed by clicking outside of the modal
                opacity: 0.5, // Opacity of modal background
                in_duration: 300, // Transition in duration
                out_duration: 200, // Transition out duration
                ready: function() {
                  $('#'+clicked_button.attr('data-id')).trigger('click');
                }, // Callback for Modal open
                complete: function() { 
                } // Callback for Modal close
            });

            $('.new-list.modal-trigger').leanModal({
                dismissible: true, // Modal can be dismissed by clicking outside of the modal
                opacity: 0.5, // Opacity of modal background
                in_duration: 300, // Transition in duration
                out_duration: 200, // Transition out duration
                ready: function() { 
                  $('button.create-triplist').attr('data-postid','');
                }, // Callback for Modal open
                complete: function() { } // Callback for Modal close
            });

            if (ajax_login_object.languages) {
              $('.languages-holder').replaceWith(ajax_login_object.languages);
            }

            $('.header__triplists').sideNav({
              menuWidth: 260, // Default is 240
              edge: 'right', // Choose the horizontal origin
              closeOnClick: true
            });

            if ($(body).hasClass('single-product')) {
              $('.logged-in-elem').show();
              $('.not-logged-in-elem').hide();

              $('.box-rates .sign-in.modal-trigger').unbind().addClass('book-inquiry book-inquiry-products').removeClass('sign-in modal-trigger').attr('href','#sidebar');
            } 

            var logoutURL = $('.action-logout').attr('href');
            start = logoutURL.indexOf('redirect_to');
            end = logoutURL.indexOf('&', start);
            urlpart = logoutURL.substring(start, end);
            logoutURL = logoutURL.replace(urlpart, 'redirect_to='+window.location.href );
            $('.action-logout').attr('href', logoutURL);

            $('.favorites.sign-in.modal-trigger').each(function() {
              $(this).unbind().addClass('alter-triplist save-to-triplist').removeClass('sign-in modal-trigger');
            });

            var favorites = data.favorites;
            for( var i in favorites ) {
              elem = document.getElementById("favorite_"+favorites[i]);
              if (elem) {
                $(elem).addClass('del-from-triplist').removeClass('save-to-triplist');
                $(elem).find('i.material-icons').html('favorite');
              }
            }

            $('.triplist-actions a.sign-in.modal-trigger').unbind().addClass('save-to-list dropdown-button').removeClass('sign-in modal-trigger');
            $('.triplist-actions .dropdown-button').dropdown({
                inDuration: 300,
                outDuration: 225,
                constrain_width: false, // Does not change width of dropdown to that of the activator
                gutter: 0, // Spacing from edge
                belowOrigin: false, // Displays dropdown below the button
                alignment: 'left' // Displays dropdown with edge aligned to the left of button
              }
            );
           
          } else {

            $('.sign-in.modal-trigger').leanModal({
                dismissible: true, // Modal can be dismissed by clicking outside of the modal
                opacity: 0.5, // Opacity of modal background
                in_duration: 300, // Transition in duration
                out_duration: 200, // Transition out duration
                ready: function() { }, // Callback for Modal open
                complete: function() { 
                  $('#signin-box').find('.active').removeClass('active');
                } // Callback for Modal close
            });

            $('.languages-holder').replaceWith(ajax_login_object.languages);
            $('a.language-holder').remove();
            $('#language-menu').removeClass('dropdown-content');

            if ($(body).hasClass('single-product')) {
              $('.not-logged-in-elem').show();
              $('.logged-in-elem').hide();
            }
          }

          $('.header__inner .dropdown-button').dropdown({
              inDuration: 300,
              outDuration: 225,
              constrain_width: false, // Does not change width of dropdown to that of the activator
              hover: true, // Activate on hover
              gutter: 0, // Spacing from edge
              belowOrigin: false, // Displays dropdown below the button
              alignment: 'left' // Displays dropdown with edge aligned to the left of button
            }
          );

        }

        function initFragments() {
          $('#home-pitch').css('height', $(window).height() * 0.7);

          var data = '';

          $.ajax({
            type: 'post',
            dataType: 'json',
            url: ajax_login_object.ajaxurl,
            data: {
              'action': 'updatefragments',
              'data': data
            },
            success: function(data) {
              afterUpdateFragments(data);
            }

          });

        }

        function initComponents() {

          $('#home-pitch').css('height', $(window).height() * 0.7);

          if (body.hasClass('logged-in')) {

            $('.header__triplists').sideNav({
                menuWidth: 260, // Default is 240
                edge: 'right', // Choose the horizontal origin
                closeOnClick: true
              });

            $('.my-profile.modal-trigger').leanModal({
                dismissible: true, // Modal can be dismissed by clicking outside of the modal
                opacity: 0.5, // Opacity of modal background
                in_duration: 300, // Transition in duration
                out_duration: 200, // Transition out duration
                ready: function() {
                  $('#'+clicked_button.attr('data-id')).trigger('click');
                }, // Callback for Modal open
                complete: function() { 
                } // Callback for Modal close
            });

            $('.new-list.modal-trigger').leanModal({
                dismissible: true, // Modal can be dismissed by clicking outside of the modal
                opacity: 0.5, // Opacity of modal background
                in_duration: 300, // Transition in duration
                out_duration: 200, // Transition out duration
                ready: function() { 
                  $('button.create-triplist').attr('data-postid','');
                }, // Callback for Modal open
                complete: function() { } // Callback for Modal close
            });
          } else {
            $('.sign-in.modal-trigger').leanModal({
              dismissible: true, // Modal can be dismissed by clicking outside of the modal
              opacity: 0.5, // Opacity of modal background
              in_duration: 300, // Transition in duration
              out_duration: 200, // Transition out duration
              ready: function() { }, // Callback for Modal open
              complete: function() { 
                $('#signin-box').find('.active').removeClass('active');
              } // Callback for Modal close
            });
          }

        }

        
        initComponents();
        //initFragments();
        $('.header__mobile-drop').sideNav();

        

        /*** MODAL TRIGGERS ***/
        $('.new-list-save.modal-trigger').leanModal({
            dismissible: true, // Modal can be dismissed by clicking outside of the modal
            opacity: 0.5, // Opacity of modal background
            in_duration: 300, // Transition in duration
            out_duration: 200, // Transition out duration
            ready: function() { 
              $('button.create-triplist').attr('data-postid',$('#mytriplists').attr('data-postid'));
            }, // Callback for Modal open
            complete: function() { } // Callback for Modal close
        });

        /*** DATE FIELDS CALENDAR INIT ***/

        var checkout = new Pikaday({
          field: $('#check_out')[0],
          numberOfMonths: 1,
          yearRange: 2, 
          format: 'MM/DD/YYYY',
          minDate: new Date()
        });

        var checkin = new Pikaday({
          field: $('#check_in')[0],
          numberOfMonths: 1,
          yearRange: 2, 
          format: 'MM/DD/YYYY',
          minDate: new Date(),
          onSelect: function() {
            checkout.gotoDate(checkin.getDate());
            checkout.setMinDate(checkin.getDate());
            checkout.show();
          }
        });

        /*** TRIPLIST FUNCTIONS ***/

        body.on('click','.save-to-list',function(e) {
          e.stopPropagation();
          var elem = $(this);
          $('.mytriplists').attr('data-postid',elem.attr('data-postid'));

          var id = elem.attr('data-activates');
          var postid = $('#'+id).attr('data-postid');

          $('#'+id).find('.alter-triplist').each(function() {
            var posts = $(this).attr('data-tpposts');
            posts = posts.split(',');
            if ($.inArray(postid, posts) > -1) {
              $(this).find('input').prop('checked',true);
              $(this).removeClass('save-to-triplist').addClass('del-from-triplist');
            } else {  
              $(this).find('input').prop('checked',false);
              $(this).removeClass('del-from-triplist').addClass('save-to-triplist');
            }
          });
        });

        body.on('click','.alter-triplist',function(e) {

          var tp_holder = $(this);
          var input = tp_holder.find('input');

          var triplist_id, post_id;
          var action = 'savetotriplist';
          var currAction = 'save-to-triplist';
          var nextAction = 'del-from-triplist';
          var checked = true;
          var fav_icon = 'favorite';

          if (tp_holder.hasClass('del-from-triplist')) {
            action = 'delfromtriplist';
            currAction = 'del-from-triplist';
            nextAction = 'save-to-triplist';
            checked = false;
            fav_icon = 'favorite_border';
          }

          if (tp_holder.hasClass('favorites')) {
            triplist_id = tp_holder.attr('data-tlid');
            post_id = tp_holder.attr('data-postid');
            tp_holder.find('.fav-icon').html(fav_icon);
          } else {
            triplist_id = tp_holder.attr('data-tlid');
            post_id = $('#mytriplists').attr('data-postid');
            var tp_posts = $(this).attr('data-tpposts');

            if (tp_holder.hasClass('del-from-triplist') && tp_posts !== '') {
              if (tp_posts.indexOf(',') > -1) {
                tp_posts = tp_posts.split(',');
                var new_list = [];
                $.each(tp_posts, function( key, value) {
                  if (value !== post_id) {
                    new_list.push(value);
                  }
                });
                tp_posts = new_list.join();
              } else {
                if (tp_posts === post_id) { tp_posts = ''; }
              }
            } else {
              if (tp_posts !== '') {
                tp_posts += ','+post_id;
              }
              else {
                tp_posts = post_id;
              }
            }
          }

          input.prop('checked',checked);

          $.ajax({
            type: 'POST',
            url: ajax_archive.ajaxurl,
            data: {
              'action': action,
              'post_id': post_id,
              'triplist_id': triplist_id
            },
            success: function(data) {
              $('.tpid_'+triplist_id).attr('data-tpposts',tp_posts);
              tp_holder.removeClass(currAction).addClass(nextAction);
              tp_holder.find('.fav-icon').html(fav_icon);
              console.log(data);
              Materialize.toast(data, 4000);
              $('.tp-button-holder').addClass('hovered');
              setTimeout(function() {
                $('.tp-button-holder').removeClass('hovered');
              },4000);
            },
            error: function() {
              input.prop('checked',!checked);
            }
          });

          e.stopPropagation();

          return false;

        });

        body.on('submit', '#create-triplist', function(e) {
          e.preventDefault();
          e.stopPropagation();

          var title = $('input#triplist-name').val();

          if (title === '') {
            alert('Please name your triplist. Thank you.');
          } else {
            var post_id = clicked_button.attr('data-postid');
            var action = clicked_button.attr('data-action');
            $.ajax({
              type: 'POST',
              url: ajax_archive.ajaxurl,
              dataType: 'json',
              data: {
                'action': action,
                'post_id': post_id,
                'title': title
              },
              success: function(data) {
                $('#create-triplist').closeModal();
                Materialize.toast(data.message, 4000);
                $('.tp-button-holder').addClass('hovered');
                setTimeout(function() {
                  $('.tp-button-holder').removeClass('hovered');
                },4000);
                var triplistsHeader = $.parseHTML(data.triplistsHeader);
                var mytriplists = $.parseHTML(data.mytriplists);
                var mytriplists2 = $.parseHTML(data.mytriplists2);
                $('#triplists-list').html($(triplistsHeader).filter('#triplists-list').html());
                $('#count-triplist-header > span').html(data.count_tl);
                $('#mytriplists').html($(mytriplists).html()).attr('data-postid','');
                $('#mytriplists2').html($(mytriplists2).html()).attr('data-postid','');
              }
            });
          }
        });

        /*** TRIPLIST ADD / REMOVE ***/
        $('body').on('click','.action-triplist', function(e) {
          e.stopPropagation();
          var elem = $(this);
          var id = elem.attr('data-id');
          var title = elem.attr('data-title');
          var action = 'addtriplist';
          if (elem.hasClass('active')) {
            action = 'removetriplist';
          }
          $.ajax({
            type: 'POST',
            dataType: 'json',
            url: ajax_archive.ajaxurl,
            data: {
              'action': action,
              'post_id': id
            },
            success: function(data) {
              var triplist = $('#triplist');
              triplist.addClass('active');

              setTimeout(function() {
                if (action === 'addtriplist') {
                  triplist.find('ul.items').append('<li id="item_'+id+'"><span>' + title + '</span> <span class="action-triplist glyphicon glyphicon-remove" aria-hidden="true" data-action="removetriplist" data-id="'+id+'"></span></li>');
                  elem.addClass('active');
                } else if (action === 'removetriplist') {
                  triplist.find('li#item_'+id).remove();
                  $('#trip_action_'+id).removeClass('active');
                }
              }, 500);
              setTimeout(function() {
                triplist.removeClass('active');
              }, 2000);
            },
            error: function(data) {
              alert(data);
            }
          });
        });

        /*** TRIPLIST - DELETE / UNDELETE ITEMS **/
        body.on('click','.delete-post, .undelete-post', function() {
          var postid = $(this).attr('data-postid');
          var action = $(this).attr('data-action');
          $.ajax({
            type: 'post',
            dataType: 'json',
            url: ajax_login_object.ajaxurl,
            data: {
              'action': action,
              'postid': postid,
            },
            success: function() {
              if (action === 'deletepost') {
                Materialize.toast('<span>Item Deleted</span><a class="undelete-post btn-flat yellow-text" href="#!" data-postid="'+postid+'" data-action="undeletepost">Undo<a>', 5000);
              } else {
                Materialize.toast('Item restored. Reloading...', 1000);
              }
            }

          });
        });

        /** END TRIPLIST FUNCTIONS ***/

        /*** JETSEMANI.COM MAIN CONTACT FORM SUBMIT ***/
        body.on('submit','form#contact-form', function(e) {

          e.preventDefault();
          var data = $(this).serialize();

          $.ajax({
            type: 'POST',
            dataType: 'json',
            url: ajax_login_object.ajaxurl,
            data: {
              'action': 'sendmailcontact',
              'data': data
            },
            success: function (data) {
              alert(data.message);
            }
          });

        });

        /** LOGIN FUNCTION **/
        function signinUser(data) {
          $.ajax({
            type: 'POST',
            dataType: 'json',
            url: ajax_login_object.ajaxurl,
            data: {
                    'action': 'signinuser', //calls wp_ajax_nopriv_ajaxlogin
                    'data': data
                },
                success: function (data) {
                  if (data.user_signed === true) {
                    var pathname = window.location.pathname;
                    document.location.href = pathname;
                  }
                },
                error: function (data) {
                  console.log('error');
                }
            });
        }
        
        /** GOOGLE LOGIN**/
        $.fn.googleLogin = function(data) {
          signinUser(data);
        };

        $(window).load(function(){
          
          if (gapi.length > 0) {
            var auth2 = gapi.auth2.getAuthInstance();

            if (auth2 && auth2.isSignedIn.get()) {
              var profile = auth2.currentUser.get().getBasicProfile();
              var data = { id: profile.getId(), firstname: profile.getGivenName(), lastname: profile.getFamilyName(), image: profile.getImageUrl(), email:profile.getEmail(), isGoogle: true };
              console.log(data);
              $(this).googleLogin(data); 
            }
          }
        });

        /*** FACEBOOK LOGIN ***/
        function fb_login() {

          FB.login(function (response) {

            if (response.authResponse) {
                  //console.log('Welcome!  Fetching your information.... ');
                  //access_token = response.authResponse.accessToken; //get access token
                  //user_id = response.authResponse.userID; //get FB UID
                  FB.api('/me', function (response) {
                    signinUser(response);
                  });

              } else {
                  //user hit cancel button
                  console.log('User cancelled login or did not fully authorize.');

              }
          }, {
            scope: 'public_profile,email,user_friends'
          });
        }

        function statusChangeCallback(response) {
          console.log(response);
          if (response.status === 'connected') {
            testAPI();
          } else if (response.status === 'not_authorized') {
            document.getElementById('status').innerHTML = 'Please log ' +
            'into this app.';
          } else {
            document.getElementById('status').innerHTML = 'Please log ' +
            'into Facebook.';
          }
        }

        function checkLoginState() {
          FB.getLoginStatus(function (response) {
            statusChangeCallback(response);
          });
        }

        function fb_logout() {
          FB.logout(function (response) {
            alert('test');
          });
        }

        body.on('click','.fb-connect', function () {
          fb_login();
        });

        body.on('click','.fb-logout', function () {
          fb_logout();
        });

        /** WORDPRESS AJAX login **/
        $('form#loginform').on('submit', function (e) {
          $('form#loginform p.status').show().text(ajax_login_object.loadingmessage);
          $.ajax({
            type: 'POST',
            dataType: 'json',
            url: ajax_login_object.ajaxurl,
            data: {
                    'action': 'ajaxlogin', //calls wp_ajax_nopriv_ajaxlogin
                    'user_email': $('form#loginform #user_email').val(),  
                    'user_pass': $('form#loginform #user_pass').val(),
                    'security': $('form#loginform #security').val()},
                    success: function (data) {
                      $('form#loginform p.status').text(data.message);
                      if (data.loggedin === true) {
                        var pathname = window.location.pathname;
                        document.location.href = pathname;
                      }
                    },
                    error: function (data) {
                      alert(data);
                    }
                });
          e.preventDefault();
        });

        /** WORDPRESS AJAX REGISTER USER **/
        $('form#register').on('submit', function (e) {
          e.preventDefault();
          if (this.user_pass.value !== this.user_pass_confirm.value) {
            $(this).find('p.status').html(ajax_login_object.mismatchpass);
          } else {
            var data = $(this).serialize();
            signinUser(data);
          }
        });

        /** FORM SUBMIT TO EDIT USER PROFILE **/
        $('form#form-user-details').on('submit',function(e) {
          e.preventDefault();
          var name = this.user_firstname.value + ' ' + this.user_lastname.value;
          var data = $(this).serialize();
          $.ajax({
            type: 'POST',
            dataType: 'json',
            url: ajax_login_object.ajaxurl,
            data: {
                  'action': 'edituserprofile', //calls wp_ajax_nopriv_ajaxlogin
                  'data': data
              },
              success: function (data) {
                $('header .user-info .username').html(name);
                $('#my-profile').closeModal();
              },
              error: function (data) {
                console.log('error');
              }
            });
        });

        /** FORM SUBMIT TO SAVE USER TASTES **/
        $('form#form-my-tastes').on('submit', function(e) {
          e.preventDefault();

          var data = $(this).serialize();

          $.ajax ({
            type: 'post',
            url: ajax_login_object.ajaxurl,
            data: {
              'action': 'savetastes',
              'data' : data
            },
            success: function(data) {
              Materialize.toast('<i class="material-icons">done<i>',400);
              $('#my-profile').closeModal();
            }
          });
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Category pages
    'category': {
      init: function() {

        var body = $('body');
        var preloader = $('.preloader-wrapper');
        var vpWidth = $(window).width();
        var vpHeight = $(window).height();

        /*** AJAX PAGINATION LOAD MORE ***/
        body.on('click','.load_more',function() {

          var elem = $(this);

          $.ajax ({
            type: 'post',
            dataType: 'html',
            url: elem.data('url'),
            success: function(data) {
              var itemsHolder = $('#items-holder');
              itemsHolder.find('.load_more').remove();
              var html = $.parseHTML(data);
              console.log($(html).find('#items-holder').html());
              itemsHolder.append($(html).find('#items-holder').html());
            }
          });

        });

        /*** GOOGLE MAPS ***/
        var map, markers, infowindow;

        function mapMarkers() {
          var Marker;
          var items = $('.geo-data');

          items.each(function(i,obj) {
            var boxitem = $(obj).parent();

            var marker = new google.maps.Marker({
              position: new google.maps.LatLng($(obj).attr('data-lat'), $(obj).attr('data-lon')),
              map: map,
              icon: 'http://cdn.jetsemani.com/wp-content/uploads/2015/09/icon-jet.png'
            });

            markers.push(marker);

            google.maps.event.addListener(marker, 'click', (function(marker, i) {
              return function(e) {
                infowindow.setContent(boxitem[0].outerHTML);
                infowindow.open(map, marker);
                if (e && e.originalEvent === undefined) {
                  boxitem.find('.action:first').trigger('click');
                }
              };
            })(marker, i));

            $(obj).parent().hover(
              function() {
                google.maps.event.trigger(marker, 'click');
              }
              );
          });
        }

        // Sets the map on all markers in the array.
        function setMapOnAll(map) {
          for (var i = 0; i < markers.length; i++) {
            markers[i].setMap(map);
          }
        }

        // Removes the markers from the map, but keeps them in the array.
        function clearMarkers() {
          setMapOnAll(null);
        }

        /*** END GOOGLE MAPS ***/

        function resetArchive() {
          $('span.stars').stars();

          if ($('#map-canvas').length) {
            clearMarkers();
            mapMarkers();
          }

        }

        if ($('#map-canvas').length) {

          markers = [];

          var myLatlng = new google.maps.LatLng(10.4236441, -75.5438075);
          var mapOptions = {
            zoom: 15,
            center: myLatlng,
            scrollwheel: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#0097a7"},{"visibility":"on"}]}]
          };
          map = new google.maps.Map(document.getElementById("map-canvas"),
            mapOptions);

           infowindow = new google.maps.InfoWindow();

          mapMarkers();
        }

                // Ordering
        //Antes llamado '#filter-archive-form'
        body.on('change','.order_by', function() {
          $('#filter-archive-form').trigger('submit');
        });

        // Filters
        // Antes llamado '#filter-archive-form'
        body.on('submit', '.filter-form-archive', function(e) {
          e.preventDefault();          
          var maindata = $(this).serialize();
          var orderby = $('input[type="radio"][name="order_by"]:checked:first').val();

          $.ajax({
            type: 'GET',
            dataType: 'html',
            url: ajax_archive.ajaxurl,
            data: {
              'action': 'filterposts',
              'maindata': maindata,
              'currency': $('input[name="currency"]').val(),
              'orderby': orderby
            },
            beforeSend: function() {
              $('#filters .collapsible-header.active').trigger('click');
              $('.content-items .items-holder').html('');
              $('.content-items .preloader-wrapper').addClass('active');
          },
          success: function(data) {
              $('.content-items .items-holder').html(data);
              $('.content-items .preloader-wrapper').removeClass('active');
              
              resetArchive();
            }
          });

        });

        var sliders = $('.range-slider');
        sliders.each(function() {
          var min = parseInt($(this).attr('data-min'));
          var max = parseInt($(this).attr('data-max'));
          var step = parseInt($(this).attr('data-step'));
          var minId = $(this).attr('data-minId');
          var maxId = $(this).attr('data-maxId');
          var input_min = document.getElementById(minId);
          var input_max = document.getElementById(maxId);
          var span_min = $('.'+minId);
          var span_max = $('.'+maxId);

          noUiSlider.create(this, {
            start: [ min, max ],
            step: 1,
            range: {
              'min': [ min ],
              'max': [ max ]
            }
          });

          // When the slider value changes, update the input and span
          this.noUiSlider.on('update', function( values, handle ) {
            var value = Math.round(values[handle],0);
            if ( handle ) {
              input_max.value = value;
              span_max.html(value);
            } else {
              var label = value;
              if (value === 0) {
                label = 'Unrated';
              }
              input_min.value = value;
              span_min.html(label);
            }
          });

        });
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'single': {
      init: function() {

        var body = $('body');
        var preloader = $('.preloader-wrapper');
        var vpWidth = $(window).width();
        var vpHeight = $(window).height();

        $('.single__gallery').magnificPopup({
          delegate: 'a', // child items selector, by clicking on it popup will open
          type: 'image',
          gallery:{
            enabled:true
          }
          // other options
        });

        if (body.hasClass('service')) {
          
          var btn_book;

          if (vpWidth > 420) {
            btn_book = $('#book-button-holder');
            btn_book.pushpin({ 
              top: btn_book.offset().top,
              bottom: $('.single__booking-form-holder').offset().top - 200
            });

            $(document).scroll(function() {
            
                $('#book-button-holder.pin-bottom').css({
                  'position': 'absolute',
                  'top': $('.single__booking-form-holder').offset().top - 200
                });
                $('#book-button-holder.pin-top').css({
                  'position': 'relative'
                });
            
            });

          } else {
            btn_book = $('#book-button-holder button.action');

            btn_book.pushpin({ 
              top: 0,
              bottom: $('.single__booking-form-holder').offset().top - 150,
              offset: vpHeight - btn_book.height()
            });

            $(document).scroll(function() {
            
                $('#book-button-holder button.action.pin-bottom').css({
                  'top': '100%'
                });
            
            });
          }
          var posx = $('#book-button-holder').offset().left;
          
        }
       

        /** GOOGLE MAPS - SINGLE ***/
        if ($('#sidebar-map').length > 0) {

            var lat = $('#latitude').text();
            var lon = $('#longitude').text();

            var fenway = new google.maps.LatLng(lat, lon);

            var mapSingleOptions = {
              center: fenway,
              zoom: 15,
              styles: [{"stylers": [{"saturation": 100}, {"gamma": 0.6}]}],
              mapTypeId: google.maps.MapTypeId.ROADMAP
            };         
            var maps = new google.maps.Map(document.getElementById('sidebar-map'), mapSingleOptions); 

            var marker = new google.maps.Marker({
              position: fenway,
              title:"Hello World!",
              icon: 'http://cdn.jetsemani.com/wp-content/uploads/2015/09/icon-jet.png',
              visible: true
            });
            marker.setMap(maps);

        }

        /*** CONTACT/BOOKING FORMS ***/

        function operate(op, n1, n2) {
          switch(op) {
              case "+":
                  return n1 + n2;
              case "-":
                  return n1 - n2;
              case "*":
                  return n1 * n2;
              case "÷":
                  return n1 / n2;
          }
        }

        /*function calculate_cost() {
          var item = $.parseJSON(item_fields.fields);
          var currency;

          if (item.default_currency !== 'custom') {
            currency = item.default_currency;
          } else {
            currency = item.currency;
          }
          var cost = item['price_'+currency];
          var persons = $('#jts_booking_persons').val();
          
          item.costs_variations.forEach(function(variation) {
            if (variation.jts_service_costs_range_type === 'persons' && parseInt(item.people_included) !== persons && persons >= variation.jts_service_costs_persons_start && persons <= variation.jts_service_costs_persons_end) {
              cost = operate(variation.jts_service_costs_range_operator, parseInt(cost), parseInt(variation['jts_service_costs_variation_'+currency]));
            }
          });

          $('#booking_total_price').html(cost.format(0, 3, '.', ','));
        } 

        var enddate = new Pikaday({
          field: $('#jts_booking_enddate')[0],
          numberOfMonths: 1,
          yearRange: 2, 
          format: 'MM/DD/YYYY',
          minDate: new Date()
        });

        var startdate = new Pikaday({
          field: $('#jts_booking_startdate')[0],
          numberOfMonths: 1,
          yearRange: 2, 
          format: 'MM/DD/YYYY',
          minDate: new Date(),
          onSelect: function() {
            if (enddate) {
              enddate.gotoDate(startdate.getDate());
              enddate.setMinDate(startdate.getDate());
              enddate.show();
            }
            calculate_cost();
          }
        });*/

        body.on('change','#jts_booking_persons', function() {
          calculate_cost();
        });

        function loading_booking(form_click,status){
          var loading = '#booking-form-'+form_click+' .loading-booking';

          if(status === 0){
            $(loading).addClass('block');
            $('div.widget-sidebar:first-child').removeClass('full-z-index');
          }

          if(status === 1){
            $('#booking-form-'+form_click+' .booking-cart').get(0).reset();            
            $('#booking-form-'+form_click).removeClass('active');  
            $('#booking-form-'+form_click+' .amount').html(' '); 
          }

          if(status === 2){
            $(loading).removeClass('block');
          }
        }       

        body.on('submit','form.booking-cart',function(e) {
          e.preventDefault();

          var form_click = $(this).attr('id');          
          loading_booking(form_click,0);
          var data = $(this).serialize();  

          $.ajax({
            type: 'POST',
            dataType: 'json',
            url: ajax_archive.ajaxurl,
            data: {
              'action': 'createbooking',
              'data': data
            },
            success: function(data) {                                           
              $('#booking-form-'+form_click+' .message-loading').html(data.message);
              document.location.href = data.thankpage;
            },
            error: function(data) {
              alert(data.message);       
              loading_booking(form_click,2); 
            }

          });
        });


        $('.slider-single').cycle({
          fx: 'fade',
          speed: 'slow',
          timeout: 7000,
          pager: '.thumbnail-slider',
          pagerTemplate: ''
        });
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
